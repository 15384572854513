<template>
  <div class="order">
    <h5 v-if="orders.length">Ваш заказ:</h5>
    <h5 v-else>Корзина пока пуста</h5>
    <div class="row" v-for="(o, index) in orders" :key="index">
        <div class="col s6 l4 pname"><i :class="foIcon[o.categoryId]"></i>{{`${o.name} ${o.side_scale} мм`}}</div>
        <div class="col s1 l2">{{o.quantity + o.units}}</div>
        <div class="col s1 l2" title="килограмм в одном метре">{{o.weight + ' кг'}}</div>
        <div class="col s1 l2">{{'стоимость: ' + calculatePrice(o.units, o.quantity, o.weight, o.price) + ' ₽'}}</div>
        <div class="col s1 l2">
          <button class="btn-floating btn-small waves-effect waves-light blue-grey darken-1 rem" @click="removeFromOrder(index, o.name)">
                <i class="material-icons">remove</i>
                </button>
                </div>
    </div>
    <div class="col s12 resultprice">Общая сумма: {{resultPrice() + ' ₽'}}</div>
    <div class="row" v-if="orders.length">
      <order-input :orders="orders"></order-input>
    </div>
  </div>
</template>

<script>
import OrderInput from '@/components/OrderInput.vue'
/**
 * TODO: FIX improper order items remove action
 * o.index is undefined
 */
export default {
  name: 'order',
  components: {
    OrderInput
  },
  computed: {
    computedOrders () {
      return this.$store.state.order
    }
  },
  methods: {
    removeFromOrder: async function (i, name) {
      this.$store.commit('removeFromOrder', i)
      this.$store.commit('addToGlobalMessages', `Вы удалили ${name} из корзины`)
      await setTimeout(() => this.$store.commit('clearGlobalMessages'), 1200)
    },
    formatFinancial: function (n) {
      return Number.parseFloat(n).toFixed(2)
    },
    calculatePrice (unit, quantity, weight, price) {
      if (unit === ' м') {
        return +this.formatFinancial(quantity * weight * price / 1000)
      } else if (unit === ' т') {
        return +this.formatFinancial(quantity * price)
      }
      return 0
    },
    resultPrice () {
      let res = 0
      this.orders.forEach(o => {
        res += this.calculatePrice(o.units, o.quantity, o.weight, o.price)
      })
      return this.formatFinancial(res)
    }
  },
  data () {
    return {
      orders: this.$store.state.order,
      foIcon: { 0: 'fo-product-pipe_rectangular',
        1: 'fo-product-corner',
        2: 'fo-product-pipe_round',
        3: 'fo-product-armature',
        4: 'fo-product-sheet' }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
hr {
  color:#009688;
  width: 80vw;
}

.pname {
  text-align: left !important;
}
.rem {
  margin-left: 4em;
}
</style>
