  <template>
    <div class="row">
    <form v-if="submitStatus === 'initial'" class="recall" id="recall-form">
        <div class="input-field col l12 s12">
          <input id="first_name" type="text" class="validate" v-model="recallName" placeholder="Имя">
        </div>
        <div class="input-field col l12 s12"><span>Контактный номер телефона</span><span class="teal-text"> *</span>
          <masked-input mask="\+\7 (111) 111-11-11" placeholder="+7 (951) 123 45 67" id="phone" type="text" class="validate" v-model="recallPhone" @input="rawVal = arguments[1]" />
          <span class="blue-grey-text text-lighten-1" v-if="errors.length" v-for="error in errors" :key="error.index">{{ error + ' ' }}</span>
        </div>
        <div class="input-field col l12 s12"><span>Куда доставить</span>
          <input placeholder="Ленина проспект, 54" id="address" type="text" class="validate" v-model="orderAddress" />
        </div>
        <div class="input-filed col l6 s12 offset-l3">
        <div class="waves-effect waves-light btn-small teal btn-recall" @click="handleFormSubmit">Оформить заказ</div>
        </div>
    </form>
      <div v-if="submitStatus === 'processing'" class="preloader-wrapper active">
        <div class="spinner-layer spinner-green-only">
          <div class="circle-clipper left">
            <div class="circle"></div>
          </div><div class="gap-patch">
            <div class="circle"></div>
          </div><div class="circle-clipper right">
            <div class="circle"></div>
          </div>
        </div>
      </div>
    <h5 v-if="submitStatus === 'sent'" class="blue-grey-text" id="recall-form">Заказ успешно отправлен, мы совсем скоро вам перезвоним</h5>
      </div>
  </template>

<script>
import axios from 'axios'
import MaskedInput from 'vue-masked-input'

export default {
  name: 'orderInput',
  props: ['orders'],
  components: {
    MaskedInput
  },
  methods: {
    handleFormSubmit: function () {
      this.errors = []

      if (this.recallPhone && this.recallPhone.length === 18 && !this.recallPhone.includes('_') && this.orders.length >= 1) {
        this.submitStatus = 'processing'
        axios
          .post('https://steelon.ru/api/v1/order', { name: this.recallName, phone: this.recallPhone, address: this.orderAddress, order: this.orders })
          .then(response => {
            this.submitStatus = 'sent'
          })
          .catch(error => {
            this.errors.push(error)
          })
      } else if (this.orders.length < 1) {
        this.errors.push('корзина пуста')
      } else {
        this.errors.push('введите телефон')
      }
    }
  },
  data () {
    return {
      submitStatus: 'initial', /* can be initial, processing and sent */
      recallName: null,
      recallPhone: null,
      orderAddress: null,
      errors: []
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.recall {
    margin: auto !important;
    width: 50% !important;
}
.btn-recall {
    margin-top: 2em;
}
</style>
